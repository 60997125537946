.MuiFab-root.contactBtn {
    position: fixed;
    bottom: 16px;
    right: 16px;
}

img,
iframe {
    border-radius: 3px;
}

.pageTitle {
    font-family: 'Roboto Flex', sans-serif;
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    line-height: 40px;
    padding-top: 16px;
}

.subTitle {
    font-family: 'Roboto Flex', sans-serif;
    text-align: center;
    font-weight: 300;
    font-size: 17.5px;
    text-transform: uppercase;
}

.imgGrid {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    position: relative;
}

.imgGrid:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
        rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    top: 0.5px;
    left: 0.5px;
}

.saverContainer {
    position: relative;
    background-color: black;
}

.saverImg {
    width: 100%;
    transition: all 0.5s ease-in-out;
    z-index: 1;
}

.saverImgBg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.MuiIconButton-root.closeSaver {
    position: absolute;
    top: 0px;
    right: 0px;
    color: white;
    background-color: rgba(0, 0, 0, 0.17);
}